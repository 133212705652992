import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, Navigate } from 'react-router-dom';

import { SIGN_IN_URL, SIGN_UP_URL, ASSET_MANAGER_URL, WELCOME_URL} from './constants/pathConstants';

import SignIn from './components/account/SignIn';
import SignUp from './components/account/SignUp';
import AssetManager from './components/AssetManager';
import Welcome from './components/Welcome';


import { AuthContext, AuthProvider } from './context/AuthContext';

import './App.css';

const ProtectedRoute = ({ user, redirectTo, children }) => {
  if (!user) {
    return <Navigate to={redirectTo} />;
  }
  return children;
};

const App = () => {
  return (
    <div className='App' style={{ backgroundColor: '#F6F6F6', minHeight: '100vh' }}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Welcome />} />
            <Route path={WELCOME_URL} element={<Welcome />} />
            <Route path={SIGN_IN_URL} element={<SignIn />} />
            <Route path={SIGN_UP_URL} element={<SignUp />} />
            <Route
              path={ASSET_MANAGER_URL + '/*'}
              element={
                <AuthContext.Consumer>
                  {({ user }) => (

                    <ProtectedRoute user={user} redirectTo={SIGN_IN_URL}>
                      <AssetManager />
                    </ProtectedRoute>
                  )}
                </AuthContext.Consumer>
              }
            />
            <Route path='*' element={<p>No page</p>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;