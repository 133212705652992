import {
  BACKEND_BASE_URL,
  REGISTER,
  LOGIN,
  DELETE,
  UPDATE,
} from "../constants/apiConstants";

export async function signUpService(data) {
  const response = await fetch(BACKEND_BASE_URL + REGISTER, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return await response;
}

export async function signInService(data) {
  const response = await fetch(BACKEND_BASE_URL + LOGIN, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return await response;
}

export async function DeleteUserService(data) {
  const response = await fetch(BACKEND_BASE_URL + DELETE, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return await response;
}

export async function UpdateUserService(data) {
  const response = await fetch(BACKEND_BASE_URL + UPDATE, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return await response;
}