import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

const Loans = () => {
  // States
  const [loans, setLoans] = useState([
    { id: 1, type: 'Mortgage', amount: 250000, term: 30, rate: 3.5, status: 'Active' },
    { id: 2, type: 'Personal', amount: 15000, term: 5, rate: 6.5, status: 'Pending' },
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingLoan, setEditingLoan] = useState(null);
  const [newLoan, setNewLoan] = useState({
    type: '',
    amount: '',
    term: '',
    rate: '',
    status: 'Pending'
  });

  // Table Columns
  const columns = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 100,
      disableColumnMenu: true,
    },
    { 
      field: 'type', 
      headerName: 'Loan Type', 
      width: 200,
      disableColumnMenu: true,
    },
    { 
      field: 'amount', 
      headerName: 'Amount ($)', 
      width: 200,
      disableColumnMenu: true,
    },
    { 
      field: 'term', 
      headerName: 'Term (Years)', 
      width: 200,
      disableColumnMenu: true,
    },
    { 
      field: 'rate', 
      headerName: 'Interest Rate (%)', 
      width: 200,
      disableColumnMenu: true,
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Handlers
  const handleAdd = () => {
    setEditingLoan(null);
    setNewLoan({
      type: '',
      amount: '',
      term: '',
      rate: '',
      status: 'Pending'
    });
    setOpenDialog(true);
  };

  const handleEdit = (loan) => {
    setEditingLoan(loan);
    setNewLoan(loan);
    setOpenDialog(true);
  };

  const handleDelete = (id) => {
    setLoans(loans.filter(loan => loan.id !== id));
  };

  const handleSave = () => {
    const loanData = {
      ...newLoan,
      amount: Number(newLoan.amount),
      term: Number(newLoan.term),
      rate: Number(newLoan.rate)
    };

    if (editingLoan) {
      setLoans(loans.map(loan => 
        loan.id === editingLoan.id ? { ...loanData, id: loan.id } : loan
      ));
    } else {
      setLoans([...loans, { ...loanData, id: loans.length + 1 }]);
    }
    setOpenDialog(false);
  };

  const filteredLoans = loans.filter(loan => 
    loan.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    loan.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">
          Loan Management
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          Add New Loan
        </Button>
      </Box>

      {/* Search Bar */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search loans..."
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Loans Table */}
      <Paper sx={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={filteredLoans}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
          hideFooterSelectedRowCount
        />
      </Paper>

      {/* Add/Edit Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editingLoan ? 'Edit Loan' : 'Add New Loan'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Loan Type"
            margin="dense"
            value={newLoan.type}
            onChange={(e) => setNewLoan({...newLoan, type: e.target.value})}
          />
          <TextField
            fullWidth
            label="Amount"
            margin="dense"
            type="number"
            value={newLoan.amount}
            onChange={(e) => setNewLoan({...newLoan, amount: Number(e.target.value)})}
          />
          <TextField
            fullWidth
            label="Term (Years)"
            margin="dense"
            type="number"
            value={newLoan.term}
            onChange={(e) => setNewLoan({...newLoan, term: Number(e.target.value)})}
          />
          <TextField
            fullWidth
            label="Interest Rate"
            margin="dense"
            type="number"
            value={newLoan.rate}
            onChange={(e) => setNewLoan({...newLoan, rate: Number(e.target.value)})}
          />
          <TextField
            fullWidth
            label="Status"
            margin="dense"
            value={newLoan.status}
            onChange={(e) => setNewLoan({...newLoan, status: e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Loans;