import * as React from 'react';
import { Link } from 'react-router-dom';

import { ASSET_MANAGER_URL, DASHBOARD_URL, ASSETS_URL, SETTINGS_URL, VALUE_FINDER_URL, LOANS_URL} from '../constants/pathConstants';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const SideBar = ({ sideBarOpen, onClose }) => {
  return (
    <Drawer open={sideBarOpen} onClose={onClose} onClick={onClose}>
      <Box sx={{ width: 270,}} role="presentation">
        <img src={`${process.env.PUBLIC_URL}/assets/DPA.png`} alt="Logo" style={{ height: '60px', width: 'auto', margin: '2px 0 0 60px' }} />
        <List>
          <ListItem key={'Dashboard'} disablePadding>
            <Link to={ASSET_MANAGER_URL + DASHBOARD_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={'Dashboard'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Assets'} disablePadding>
            <Link to={ASSET_MANAGER_URL + ASSETS_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary={'Assets'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Loans'} disablePadding>
            <Link to={ASSET_MANAGER_URL + LOANS_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={'Loans'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Settings'} disablePadding>
            <Link to={ASSET_MANAGER_URL + SETTINGS_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Info'} disablePadding>
            <Link to={ASSET_MANAGER_URL + DASHBOARD_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={'Info'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key='ValueFinder' disablePadding>
            <Link to={ASSET_MANAGER_URL + VALUE_FINDER_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary='Value Finder' />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

export default SideBar;