import React, { useState, useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DeleteUserService, UpdateUserService } from '../../services/userService';

const Settings = () => {
  const { user, logout } = useContext(AuthContext);
  const [email, setEmail] = useState({ value: null, error: false, message: '' });
  const [firstName, setFirstName] = useState({ value: null, error: false, message: '' });
  const [lastName, setLastName] = useState({ value: null, error: false, message: '' });
  const [company, setCompany] = useState({ value: null, error: false, message: '' });

  const handleClickDeleteAccount = async () => {
    try {
      const response = await DeleteUserService(user.email);
      if (response.ok) {
        logout();
      } else {
        console.error('Failed to delete account');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleClickSave = async () => {
    try {
      if (!validateInputs()) {
        return;
      }

      setEmail(x => ({ ...x, value: document.getElementById('email').value }));
      setFirstName(x => ({ ...x, value: document.getElementById('first-name').value }));
      setLastName(x => ({ ...x, value: document.getElementById('last-name').value }));
      setCompany(x => ({ ...x, value: document.getElementById('company').value }));

      user.email = document.getElementById('email').value;
      user.firstName = document.getElementById('first-name').value;
      user.lastName = document.getElementById('last-name').value;
      user.company = document.getElementById('company').value;
      user.fullName = `${user.firstName} ${user.lastName}`;

      console.log(user);
      const response = await UpdateUserService({id: user.id, fullName: user.fullName, username: user.username, company: user.company, email: user.email});
      if (response.ok) {
        alert('Information updated successfully');
      } else {
        console.error('Failed to update user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const validateInputs = () => {
    const email = document.getElementById('email');
    const firstName = document.getElementById('first-name');
    const lastName = document.getElementById('last-name');
    const company = document.getElementById('company');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmail(x => ({ ...x, error: true, message: 'Please enter a valid email address' }));
      isValid = false;
    } else {
      setEmail(x => ({ ...x, value: email.value, error: false, message: '' }));
    }

    if (!firstName.value) {
      setFirstName(x => ({ ...x, error: true, message: 'Please enter your first name' }));
      isValid = false;
    } else {
      setFirstName(x => ({ ...x, value: firstName.value, error: false, message: '' }));
    }

    if (!lastName.value) {
      setLastName(x => ({ ...x, error: true, message: 'Please enter your last name' }));
      isValid = false;
    } else {
      setLastName(x => ({ ...x, value: lastName.value, error: false, message: '' }));
    }

    if (company.value) {
      setCompany(x => ({ ...x, value: company.value, error: false, message: '' }));
    }

    return isValid;
  };

  return (
    <Box className='Settings manager-box'>
      <div className='flex-space'>
        <Typography className='title' variant="h5">
          Settings
        </Typography>
        <div>
          <Button
            variant='contained'
            color='success'
            sx={{ height: '30px', marginRight: '20px' }}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </div>
      </div>

      <div className='flex-space'>
        <TextField
          required
          id='first-name'
          label='First Name'
          defaultValue={user.firstName}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
        <TextField
          required
          id='last-name'
          label='Last Name'
          defaultValue={user.lastName}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
      </div>

      <div className='flex-space'>
        <TextField
          id='company'
          label='Company'
          defaultValue={user.company}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
        <TextField
          required
          id='email'
          label='Email'
          defaultValue={user.email}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button
          variant='outlined'
          color='error'
          sx={{ height: '30px' }}
          onClick={handleClickDeleteAccount}
        >
          Delete Account
        </Button>
      </div>
    </Box>
  );
}

export default Settings;